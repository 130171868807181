<template>
  <div>
    <NavHeader :reviews="reviews" />
    <main
      :class="{ 'pt-40': announcement !== 'closed' && navigation?.announcement?.info }"
    >
      <slot />
    </main>
    <footer class="footer">
      <div class="py-25 px-0 container">
        <ul class="flex flex-wrap justify-center lg:justify-between items-center">
          <li>
            <NuxtLink
              to="/"
              aria-label="logo"
              class="text-secondary block mt-10 mb-50 md:mb-10 mx-15 xl:mx-25"
            >
              <logo
                class="h-[42px] w-auto"
              />
            </NuxtLink>
          </li>
          <li>
            <ul class="flex flex-wrap items-center justify-center text-secondary xl:mx-10">
              <li class="border border-secondary w-[70px] h-[40px] flex items-center mx-15 my-10 justify-center">
                <applepayIcon />
              </li>
              <li class="border border-secondary w-[70px] h-[40px] flex items-center mx-15 my-10 justify-center">
                <idealIcon />
              </li>
              <li class="border border-secondary w-[70px] h-[40px] flex items-center mx-15 my-10 justify-center">
                <mastercardIcon />
              </li>
              <li class="border border-secondary w-[70px] h-[40px] flex items-center mx-15 my-10 justify-center">
                <paypalIcon />
              </li>
              <li class="border border-secondary w-[70px] h-[40px] flex items-center mx-15 my-10 justify-center">
                <visaIcon />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="bg-primary-100 py-50">
        <div class="container">
          <ul class="flex flex-wrap items-start justify-between text-sm xl:text-base text-center sm:text-left">
            <li
              v-for="(nav, col) in footer"
              :key="`footer-${col}`"
              :class="{ 'mt-50': col !== 'col1', 'sm:mt-0': col === 'col2', 'lg:mt-0': col === 'col3' }"
              class="w-full sm:w-1/2 lg:w-1/4"
            >
              <ul :class="[col === 'col1' ? 'text-3xl xl:text-4xl font-bold' : 'space-y-5']">
                <li
                  v-for="item in nav"
                  :key="`footer-item-${item.id}`"
                >
                  <NuxtLink
                    :to="`/${item.uri}`"
                    class="lowercase"
                  >
                    {{ item.title }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li class="w-full sm:w-1/2 lg:w-1/4 mt-50 lg:mt-0">
              <ul class="space-y-5 text-primary-500">
                <template
                  v-for="store in stores?.stores"
                  :key="`footer-store-${store.id}`"
                >
                  <li>
                    <b>{{ store.storeTitle }}</b>
                  </li>
                  <li>
                    {{ store.addressStreet }}
                  </li>
                  <li>
                    <NuxtLink
                      :to="`/${stores?.uri}#open`"
                      class="underline hover:text-primary-700"
                      external
                    >
                      {{ $t('nav.opening-times') }}
                    </NuxtLink>
                  </li>
                  <li>&nbsp;</li>
                </template>
                <li>
                  <ul class="flex text-secondary justify-center sm:justify-start">
                    <li
                      v-if="navigation?.socials?.instagram"
                      class="mr-10"
                    >
                      <NuxtLink
                        :to="navigation.socials.instagram"
                        external
                        aria-label="instagram"
                        class="border border-secondary w-[40px] h-[40px] flex items-center justify-center hover:bg-secondary hover:text-white transition"
                      >
                        <instagramIcon />
                      </NuxtLink>
                    </li>
                    <li
                      v-if="navigation?.socials?.facebook"
                      class="mr-10"
                    >
                      <NuxtLink
                        :to="navigation.socials.facebook"
                        external
                        aria-label="facebook"
                        class="border border-secondary w-[40px] h-[40px] flex items-center justify-center hover:bg-secondary hover:text-white transition"
                      >
                        <facebookIcon />
                      </NuxtLink>
                    </li>
                    <li
                      v-if="navigation?.socials?.pinterest"
                      class="mr-10"
                    >
                      <NuxtLink
                        :to="navigation.socials.pinterest"
                        external
                        aria-label="pinterest"
                        class="border border-secondary w-[40px] h-[40px] flex items-center justify-center hover:bg-secondary hover:text-white transition"
                      >
                        <pinterestIcon />
                      </NuxtLink>
                    </li>
                    <li v-if="navigation?.socials?.whatsapp">
                      <NuxtLink
                        :to="`https://wa.me/${navigation.socials.whatsapp}`"
                        external
                        aria-label="whatsapp"
                        class="border border-secondary w-[40px] h-[40px] flex items-center justify-center hover:bg-secondary hover:text-white transition"
                      >
                        <whatsappIcon
                          width="30"
                          height="30"
                        />
                      </NuxtLink>
                    </li>
                  </ul>
                </li>
                <li>&nbsp;</li>
              </ul>
            </li>
            <li class="flex items-center justify-center flex-wrap gap-30 w-full md:w-fit">
              <NuxtLink
                v-if="reviews?.detailslink"
                :to="reviews.detailslink"
                target="_blank"
                :aria-label="$t('general.rating')"
                class="block"
              >
                <logoFeedbackCompany width="128" />
              </NuxtLink>
              <div>
                {{ $t('general.rating') }}<br>
                <NuxtLink
                  :to="reviews.detailslink"
                  target="_blank"
                  class="flex items-center space-x-2 justify-center sm:justify-start"
                >
                  <template v-for="i in 5">
                    <starFilledIcon
                      v-if="i <= reviews.score"
                      :key="`star-filled-${i}`"
                    />
                    <starHalfIcon
                      v-else-if="(i - 0.5) <= reviews.score"
                      :key="`star-half-${i}`"
                    />
                    <starIcon
                      v-else
                      :key="`star-${i}`"
                    />
                  </template>
                  <span class="translate-y-1 pl-5">{{ (reviews.score * 2) }}/{{ (reviews.scoremax * 2) }}</span>
                </NuxtLink>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="py-35 container">
        <ul class="text-sm flex space-x-25">
          <li>
            <NuxtLink
              :to="`/${terms?.uri}`"
              class="hover:underline lowercase"
            >
              {{ terms?.title }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              :to="`/${privacy?.uri}`"
              class="hover:underline lowercase"
            >
              {{ privacy?.title }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              :to="`/${disclaimer?.uri}`"
              class="hover:underline lowercase"
            >
              {{ disclaimer?.title }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script setup>
import _groupBy from 'lodash.groupby';
import {
  useI18n,
  useAsyncGql,
  useState,
  useStatefulFetch,
  computed,
} from '#imports';

// Logo
import logo from '@/assets/img/logo.svg';
import logoFeedbackCompany from '@/assets/img/logo-feedback-company.svg';

// General icons
import starIcon from '@/assets/img/icon-star.svg';
import starHalfIcon from '@/assets/img/icon-star-half.svg';
import starFilledIcon from '@/assets/img/icon-star-filled.svg';

// Social icons
import instagramIcon from '@/assets/img/icon-instagram.svg';
import facebookIcon from '@/assets/img/icon-facebook.svg';
import pinterestIcon from '@/assets/img/icon-pinterest.svg';
import whatsappIcon from '@/assets/img/icon-whatsapp.svg';

// Payment icons
import applepayIcon from '@/assets/img/icon-applepay.svg';
import idealIcon from '@/assets/img/icon-ideal.svg';
import mastercardIcon from '@/assets/img/icon-mastercard.svg';
import paypalIcon from '@/assets/img/icon-paypal.svg';
import visaIcon from '@/assets/img/icon-visa.svg';

// Data
const announcement = useState('announcement');
const { locale } = useI18n({ useScope: 'global' });
const [{ data: navigation }, { data: reviewsData }] = await Promise.all([
  useAsyncGql('navigation', { site: locale.value }, { key: 'navigation' }),
  useStatefulFetch('/actions/reviews/scores', {
    key: 'reviews',
    server: true,
    params: {
      site: locale.value,
    },
  }),
]);
const reviews = useState('reviews', () => reviewsData);

// Computed properties
const stores = computed(() => navigation.value?.stores);
const footer = computed(() => _groupBy(navigation.value?.footer, 'navigationColumn'));
const terms = computed(() => navigation.value?.terms);
const privacy = computed(() => navigation.value?.privacy);
const disclaimer = computed(() => navigation.value?.disclaimer);
</script>
