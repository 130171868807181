<template>
  <header
    :class="[
      'fixed top-0 left-0 w-full z-[99]',
      { 'menu-is-open': menuIsOpen },
      { 'lg:top-40': announcement === 'closed' },
    ]"
  >
    <div
      role="button"
      class="fixed lg:hidden top-0 left-0 w-full h-full bg-black/30 transition-all invisible opacity-0 in-[.menu-is-open]:opacity-100 in-[.menu-is-open]:visible"
      tabindex="0"
      @click="menuIsOpen = false"
    />

    <div
      v-if="announcement !== 'closed' && navigation?.announcement?.info"
      class="relative bg-secondary text-white text-center py-5 md:py-10 z-10 min-h-[40px] flex items-center justify-center"
    >
      <p class="text-2xs md:text-xs lg:text-sm px-50">
        {{ navigation.announcement.info }}
      </p>
      <button
        class="absolute right-0 inset-y-0 px-10 flex items-center group"
        @click="announcement = 'closed'"
      >
        <CloseIcon class="rotate-45 group-hover:rotate-[135deg] transition" />
      </button>
    </div>

    <div class="container">
      <div class="relative">
        <div
          :class="[
            'px-20 bg-white lg:px-25 py-5 flex justify-center lg:justify-between border-x lg:border-t border-primary-700 relative z-10 max-lg:max-h-auto max-lg:transition-transform max-lg:transform-gpu',
            { 'max-md:translate-y-[101%] max-lg:max-h-10': scrolled },
          ]"
        >
          <HeaderReviews :reviews="reviews" />

          <ul class="text-3xs gap-x-25 hidden lg:flex">
            <li v-if="stores">
              <NuxtLink
                :to="`/${stores.uri}`"
                class="hover:text-primary-500 transition-colors hover:underline"
              >
                {{ stores.title }}
              </NuxtLink>
            </li>
            <li v-if="about">
              <NuxtLink
                :to="`/${about.uri}`"
                class="hover:text-primary-500 transition-colors hover:underline"
              >
                {{ about.title }}
              </NuxtLink>
            </li>
            <li
              v-for="lang in $i18n.locales.filter((l) => $i18n.locale !== l.code)"
              :key="`language-${lang.code}`"
            >
              <NuxtLink
                class="hover:text-primary-500 transition-colors hover:underline flex items-center gap-x-3"
                :to="switchLocalePath(lang.code)"
                :aria-label="lang.name"
                external
              >
                <div class="rounded-full overflow-hidden">
                  <Component
                    :is="langIcons[lang.code]"
                    width="15"
                    height="15"
                  />
                </div>
                {{ lang.code }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <!-- Main menu bar -->
        <div class="border bg-white border-primary-700 h-75 flex lg:justify-between relative z-10">
          <NuxtLink
            to="/"
            :aria-label="$t('general.home')"
            class="flex items-center flex-1 lg:flex-none pl-20 lg:pl-25 lg:pr-25"
          >
            <Logo
              class="w-auto"
              width="109"
              height="28"
            />
          </NuxtLink>

          <!-- Navigation -->
          <nav
            ref="nav"
            :class="[
              'bg-white max-lg:transition-all max-lg:h-[calc(100dvh-118px)] max-lg:overflow-y-auto max-lg:overflow-x-hidden max-lg:absolute max-lg:top-full max-lg:-inset-x-1 max-lg:border max-lg:border-primary-700',
              menuIsOpen ? 'max-md:visible max-lg:opacity-100 max-lg:pointer-events-auto' : 'max-md:invisible max-lg:opacity-0 max-lg:pointer-events-none',
            ]"
          >
            <ul class="lg:flex lg:h-full">
              <li class="p-20 lg:hidden">
                <!-- Mobile search bar -->
                <form @submit.prevent="onSearch(searchQueryRef)">
                  <label class="block w-full relative">
                    <button
                      type="submit"
                      :aria-label="$t('general.search')"
                      class="absolute left-0 top-0 h-full flex items-center pl-15 pr-10"
                    >
                      <MagnifyingGlassIcon height="25" />
                    </button>
                    <input
                      v-model="searchQueryRef"
                      type="search"
                      class="bg-primary-100 pl-50 pr-15 rounded-md placeholder:text-primary-500 text-xs h-60 w-full focus:outline-none focus:border-primary-700 border border-transparent transition-colors"
                      :placeholder="`${$t('search.collection')}...`"
                    >
                  </label>
                </form>
              </li>

              <!-- Collection -->
              <li class="group">
                <div class="lg:h-full max-lg:flex">
                  <button
                    type="button"
                    class="group lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg flex items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                    @click="openSubmenu.collection.active = true"
                  >
                    {{ $t('nav.collection') }}
                    <PlusIcon
                      width="12"
                      height="12"
                      class="-translate-y-1 transition-transform group-hover:rotate-90 hidden lg:block"
                    />
                  </button>
                  <button
                    type="button"
                    class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                    @click="openSubmenu.collection.active = true"
                  >
                    <ChevronIcon class="w-25 -rotate-90" />
                  </button>
                </div>

                <!-- Submenu Collection -->
                <div
                  :class="[
                    'md:invisible lg:pointer-events-none lg:opacity-0 lg:transition-all lg:group-hover:visible lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 bg-white lg:max-h-[calc(100dvh-250px)] lg:overflow-y-auto absolute max-lg:top-0 inset-x-0 lg:-inset-x-1 max-lg:transition-transform max-lg:transition-gpu lg:border lg:border-primary-700 max-lg:min-h-[--nav-height]',
                    openSubmenu.collection.active ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                  ]"
                  :style="openSubmenu.collection.active ? `--nav-height: ${$refs.nav.scrollHeight}px` : null"
                >
                  <button
                    type="button"
                    class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                    @click="openSubmenu.collection.active = false"
                  >
                    <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                      <ChevronIcon class="w-25 rotate-90" />
                    </div>
                    <span class="block w-full ellipsis">
                      {{ $t('nav.collection') }}
                    </span>
                  </button>
                  <div class="lg:grid lg:grid-cols-12">
                    <!-- Children of collection -->
                    <ul class="py-20 lg:pl-20 lg:col-span-3">
                      <li
                        v-for="item in collection"
                        :key="`collection-subitem-${item.id}`"
                        class="flex items-center justify-between gap-x-5 group/item"
                        @mouseenter="handleMouseEvent(openSubmenu.collection, item.id, true)"
                        @focus="handleMouseEvent(openSubmenu.collection, item.id, true)"
                        @mouseleave="handleMouseEvent(openSubmenu.collection, item.id, false)"
                        @blur="handleMouseEvent(openSubmenu.collection, item.id, false)"
                      >
                        <NuxtLink
                          :to="`/${item.uri}`"
                          class="hidden lg:block leading-none text-xl md:text-2xl lg:text-sm xl:text-lg px-20 text-left font-medium flex-1 ellipsis lg:group-hover/item:bg-primary-100 lg:text-primary-500 lg:transition-colors lg:group-hover/item:underline lg:font-bold lg:py-10"
                        >
                          {{ item.title }}
                        </NuxtLink>
                        <button
                          type="button"
                          class="block lg:hidden leading-none text-xl md:text-2xl lg:text-sm xl:text-lg px-20 text-left font-medium flex-1 ellipsis lg:group-hover/item:bg-primary-100 lg:text-primary-500 lg:transition-colors lg:group-hover/item:underline lg:font-bold lg:py-10"
                          @click="openSubmenu.collection.subActive = true, openSubmenu.collection[item.id] = true"
                        >
                          {{ item.title }}
                        </button>
                        <button
                          type="button"
                          :aria-label="`${item.title} submenu`"
                          class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                          @click="openSubmenu.collection.subActive = true, openSubmenu.collection[item.id] = true"
                        >
                          <ChevronIcon class="w-25 -rotate-90" />
                        </button>
                      </li>
                    </ul>

                    <!-- Subchildren of collection -->
                    <div
                      :class="[
                        'md:col-span-5 bg-white absolute lg:relative max-lg:top-0 max-lg:inset-x-0 max-lg:transition-transform max-lg:transition-gpu max-lg:min-h-[--nav-height]',
                        openSubmenu.collection.subActive ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                      ]"
                      :style="openSubmenu.collection.subActive ? `--nav-height: ${$refs.nav.scrollHeight}px` : null"
                    >
                      <div
                        v-for="item in collection"
                        :key="`collection-subchild-${item.key}`"
                        :class="openSubmenu.collection[item.id] ? 'block' : 'hidden'"
                        class="lg:min-h-full"
                        @mouseenter="handleMouseEvent(openSubmenu.collection, item.id, true)"
                        @focus="handleMouseEvent(openSubmenu.collection, item.id, true)"
                        @mouseleave="handleMouseEvent(openSubmenu.collection, item.id, false)"
                        @blur="handleMouseEvent(openSubmenu.collection, item.id, false)"
                      >
                        <button
                          type="button"
                          class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                          @click="openSubmenu.collection.subActive = false, openSubmenu.collection[item.id] = false"
                        >
                          <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                            <ChevronIcon class="w-25 rotate-90" />
                          </div>
                          <span class="block w-full ellipsis">
                            {{ item.title }}
                          </span>
                        </button>
                        <ul class="py-20">
                          <li
                            v-for="child in item.children"
                            :key="`collection-subchilditem-${child.id}`"
                            class="flex items-center justify-between gap-x-5"
                          >
                            <NuxtLink
                              :to="`/${child.uri}`"
                              class="px-20 py-[12px] lg:py-10 text-xl md:text-2xl lg:text-sm xl:text-lg leading-none text-left text-primary-500 flex items-center justify-between flex-1 lg:hover:underline"
                            >
                              {{ child.title }}
                            </NuxtLink>
                          </li>
                          <li class="lg:hidden p-20">
                            <NuxtLink
                              :to="`/${item.uri}`"
                              class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-base px-20 py-15 h-full items-center justify-center flex font-medium"
                            >
                              {{ $t('nav.explore-all', { title: item.title }) }}
                            </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      v-if="banner?.collectionTitle"
                      class="p-10 lg:p-20 lg:col-span-4"
                    >
                      <div class="px-20 py-25 bg-primary-100">
                        <h4
                          class="wysiwyg-title text-2xl lg:text-xl xl:text-2xl"
                          v-html="banner.collectionTitle"
                        />
                        <NuxtLink
                          v-if="banner.collectionLink?.url"
                          :to="banner.collectionLink.url"
                          :target="banner.collectionLink.target"
                          class="inline-flex items-center text-xl font-serif underline space-x-5 group mt-15 text-primary-500 transition-colors hover:text-primary-700"
                        >
                          <span>{{ banner.collectionLink.text }}</span>
                          <ChevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
                        </NuxtLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- Rooms -->
              <li
                v-if="roomsOverview"
                class="group"
              >
                <div class="lg:h-full max-lg:flex">
                  <NuxtLink
                    :to="`/${roomsOverview.uri}`"
                    class="group hidden lg:flex lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                  >
                    {{ roomsOverview.title }}
                    <PlusIcon
                      width="12"
                      height="12"
                      class="-translate-y-1 transition-transform group-hover:rotate-90"
                    />
                  </NuxtLink>
                  <button
                    type="button"
                    class="group flex lg:hidden lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                    @click="openSubmenu.rooms.active = true"
                  >
                    {{ roomsOverview.title }}
                  </button>
                  <button
                    type="button"
                    class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                    @click="openSubmenu.rooms.active = true"
                  >
                    <ChevronIcon class="w-25 -rotate-90" />
                  </button>
                </div>

                <!-- Submenu Rooms -->
                <div
                  :class="[
                    'md:invisible lg:pointer-events-none lg:opacity-0 lg:transition-all lg:group-hover:visible lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 bg-white lg:max-h-[calc(100dvh-250px)] lg:overflow-y-auto absolute max-lg:top-0 inset-x-0 lg:-inset-x-1 max-lg:transition-transform max-lg:transition-gpu lg:border lg:border-primary-700 max-lg:min-h-full',
                    openSubmenu.rooms.active ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                  ]"
                  :style="openSubmenu.rooms.active ? `min-height: ${$refs.nav.scrollHeight}px` : null"
                >
                  <button
                    type="button"
                    class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                    @click="openSubmenu.rooms.active = false"
                  >
                    <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                      <ChevronIcon class="w-25 rotate-90" />
                    </div>
                    <span class="block w-full ellipsis">
                      {{ roomsOverview.title }}
                    </span>
                  </button>
                  <ul class="p-20 lg:p-25 grid grid-cols-2 gap-15 lg:grid-cols-7 lg:gap-10">
                    <li
                      v-for="item in rooms"
                      :key="`rooms-menuitem-${item.id}`"
                    >
                      <NuxtLink
                        :to="`/${item.uri}`"
                        class="block group/item"
                      >
                        <figure>
                          <div class="aspect-[148/170] bg-primary-100">
                            <img
                              v-if="item.image.length"
                              :src="item.image[0].url"
                              :alt="item.title"
                              fetchpriority="low"
                              class="object-cover w-full h-full"
                            >
                          </div>
                          <figcaption class="text-lg text-primary-500 font-bold mt-2 lg:mt-5 group-hover/item:underline">
                            {{ item.title }}
                          </figcaption>
                        </figure>
                      </NuxtLink>
                    </li>
                    <li class="lg:hidden col-span-2">
                      <NuxtLink
                        :to="`/${roomsOverview.uri}`"
                        class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-base px-20 py-15 h-full items-center justify-center flex font-medium"
                      >
                        {{ $t('nav.explore-all', { title: roomsOverview.title }) }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </li>

              <!-- Accessories -->
              <li
                v-if="accessoriesOverview"
                class="group"
              >
                <div class="lg:h-full max-lg:flex">
                  <NuxtLink
                    :to="`/${accessoriesOverview.uri}`"
                    class="group hidden lg:flex lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                  >
                    {{ accessoriesOverview.title }}
                    <PlusIcon
                      width="12"
                      height="12"
                      class="-translate-y-1 transition-transform group-hover:rotate-90"
                    />
                  </NuxtLink>
                  <button
                    type="button"
                    class="group flex lg:hidden lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                    @click="openSubmenu.accessories.active = true"
                  >
                    {{ accessoriesOverview.title }}
                  </button>
                  <button
                    type="button"
                    class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                    @click="openSubmenu.accessories.active = true"
                  >
                    <ChevronIcon class="w-25 -rotate-90" />
                  </button>
                </div>

                <!-- Submenu accessories -->
                <div
                  :class="[
                    'md:invisible lg:pointer-events-none lg:opacity-0 lg:transition-all lg:group-hover:visible lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 bg-white lg:max-h-[calc(100dvh-250px)] lg:overflow-y-auto absolute max-lg:top-0 inset-x-0 lg:-inset-x-1 max-lg:transition-transform max-lg:transition-gpu lg:border lg:border-primary-700 max-lg:min-h-[--nav-height]',
                    openSubmenu.accessories.active ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                  ]"
                  :style="openSubmenu.accessories.active ? `--nav-height: ${$refs.nav.scrollHeight}px` : null"
                >
                  <button
                    type="button"
                    class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                    @click="openSubmenu.accessories.active = false"
                  >
                    <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                      <ChevronIcon class="w-25 rotate-90" />
                    </div>
                    <span class="block w-full ellipsis">
                      {{ accessoriesOverview.title }}
                    </span>
                  </button>
                  <div class="lg:grid lg:grid-cols-12">
                    <!-- Children of accessories -->
                    <ul class="py-20 lg:pl-20 lg:grid lg:grid-cols-3 lg:gap-x-10 lg:col-span-8">
                      <li
                        v-for="item in accessories"
                        :key="`accessories-subitem-${item.id}`"
                        class="flex items-center justify-between gap-x-5 group/item"
                      >
                        <NuxtLink
                          :to="`/${item.uri}`"
                          class="px-20 py-[12px] lg:py-10 text-xl md:text-2xl lg:text-sm xl:text-lg leading-none text-left text-primary-500 flex items-center justify-between flex-1 lg:hover:underline"
                        >
                          {{ item.title }}
                        </NuxtLink>
                      </li>
                      <li class="lg:hidden p-20">
                        <NuxtLink
                          :to="`/${accessoriesOverview.uri}`"
                          class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-base px-20 py-15 h-full items-center justify-center flex font-medium"
                        >
                          {{ $t('nav.explore-all', { title: accessoriesOverview.title }) }}
                        </NuxtLink>
                      </li>
                    </ul>

                    <div
                      v-if="banner?.accessoriesTitle"
                      class="p-10 lg:p-20 lg:col-span-4"
                    >
                      <div class="px-20 py-25 bg-primary-100">
                        <h4
                          class="wysiwyg-title text-2xl lg:text-xl xl:text-2xl"
                          v-html="banner.accessoriesTitle"
                        />
                        <NuxtLink
                          v-if="banner.accessoriesLink?.url"
                          :to="banner.accessoriesLink.url"
                          :target="banner.accessoriesLink.target"
                          class="inline-flex items-center text-xl font-serif underline space-x-5 group mt-15 text-primary-500 transition-colors hover:text-primary-700"
                        >
                          <span>{{ banner.accessoriesLink.text }}</span>
                          <ChevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
                        </NuxtLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- Inpsiration -->
              <li
                v-if="inspirationOverview"
                class="group"
              >
                <div class="lg:h-full max-lg:flex">
                  <NuxtLink
                    :to="`/${inspirationOverview.uri}`"
                    class="group hidden lg:flex lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                  >
                    {{ inspirationOverview.title }}
                    <PlusIcon
                      width="12"
                      height="12"
                      class="-translate-y-1 transition-transform group-hover:rotate-90"
                    />
                  </NuxtLink>
                  <button
                    type="button"
                    class="group flex lg:hidden lg:h-full max-lg:flex-1 px-20 lg:px-10 xl:px-20 text-xl md:text-2xl lg:text-sm xl:text-lg items-center gap-x-5 font-medium hover:text-primary-500 transition-colors max-lg:ellipsis"
                    @click="openSubmenu.inspiration.active = true"
                  >
                    {{ inspirationOverview.title }}
                  </button>
                  <button
                    type="button"
                    class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                    @click="openSubmenu.inspiration.active = true"
                  >
                    <ChevronIcon class="w-25 -rotate-90" />
                  </button>
                </div>

                <!-- Submenu inspiration -->
                <div
                  :class="[
                    'md:invisible lg:pointer-events-none lg:opacity-0 lg:transition-all lg:group-hover:visible lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 bg-white lg:max-h-[calc(100dvh-250px)] lg:overflow-y-auto absolute max-lg:top-0 inset-x-0 lg:-inset-x-1 max-lg:transition-transform max-lg:transition-gpu lg:border lg:border-primary-700 max-lg:min-h-[--nav-height]',
                    openSubmenu.inspiration.active ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                  ]"
                  :style="openSubmenu.inspiration.active ? `--nav-height: ${$refs.nav.scrollHeight}px` : null"
                >
                  <button
                    type="button"
                    class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                    @click="openSubmenu.inspiration.active = false"
                  >
                    <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                      <ChevronIcon class="w-25 rotate-90" />
                    </div>
                    <span class="block w-full ellipsis">
                      {{ inspirationOverview.title }}
                    </span>
                  </button>
                  <div class="lg:grid lg:grid-cols-12">
                    <!-- Children of inspiration -->
                    <ul class="py-20 lg:pl-20 lg:col-span-3">
                      <li
                        v-for="item in inspiration"
                        :key="`inspiration-subitem-${item.id}`"
                        class="flex items-center justify-between gap-x-5 group/item"
                        @mouseenter="handleMouseEvent(openSubmenu.inspiration, item.id, true)"
                        @focus="handleMouseEvent(openSubmenu.inspiration, item.id, true)"
                        @mouseleave="handleMouseEvent(openSubmenu.inspiration, item.id, false)"
                        @blur="handleMouseEvent(openSubmenu.inspiration, item.id, false)"
                      >
                        <NuxtLink
                          :to="`/${item.uri}`"
                          class="hidden lg:block leading-none text-xl md:text-2xl lg:text-sm xl:text-lg px-20 text-left font-medium flex-1 ellipsis lg:group-hover/item:bg-primary-100 lg:text-primary-500 lg:transition-colors lg:group-hover/item:underline lg:font-bold lg:py-10"
                        >
                          {{ item.title }}
                        </NuxtLink>
                        <button
                          type="button"
                          class="block lg:hidden leading-none text-xl md:text-2xl lg:text-sm xl:text-lg px-20 text-left font-medium flex-1 ellipsis lg:group-hover/item:bg-primary-100 lg:text-primary-500 lg:transition-colors lg:group-hover/item:underline lg:font-bold lg:py-10"
                          @click="openSubmenu.inspiration.subActive = true, openSubmenu.inspiration[item.id] = true"
                        >
                          {{ item.title }}
                        </button>
                        <button
                          type="button"
                          :aria-label="`${item.title} submenu`"
                          class="px-20 py-[12px] text-primary-500 hover:bg-primary-100 focus:bg-primary-100 lg:hidden"
                          @click="openSubmenu.inspiration.subActive = true, openSubmenu.inspiration[item.id] = true"
                        >
                          <ChevronIcon class="w-25 -rotate-90" />
                        </button>
                      </li>
                    </ul>

                    <!-- Subchildren of inspiration -->
                    <div
                      :class="[
                        'md:col-span-5 bg-white absolute lg:relative max-lg:top-0 max-lg:inset-x-0 max-lg:transition-transform max-lg:transition-gpu max-lg:min-h-[--nav-height]',
                        openSubmenu.inspiration.subActive ? 'max-md:translate-x-0 max-lg:z-10' : 'max-md:translate-x-[101%] max-lg:z-auto',
                      ]"
                      :style="openSubmenu.inspiration.subActive ? `--nav-height: ${$refs.nav.scrollHeight}px` : null"
                    >
                      <div
                        v-for="item in inspiration"
                        :key="`inspiration-subchild-${item.key}`"
                        :class="openSubmenu.inspiration[item.id] ? 'block' : 'hidden'"
                        class="lg:min-h-full"
                        @mouseenter="handleMouseEvent(openSubmenu.inspiration, item.id, true)"
                        @focus="handleMouseEvent(openSubmenu.inspiration, item.id, true)"
                        @mouseleave="handleMouseEvent(openSubmenu.inspiration, item.id, false)"
                        @blur="handleMouseEvent(openSubmenu.inspiration, item.id, false)"
                      >
                        <button
                          type="button"
                          class="px-20 pt-20 text-xl md:text-2xl text-left font-medium flex gap-x-10 md:gap-x-15 items-center focus:outline-none group/button lg:hidden max-w-full"
                          @click="openSubmenu.inspiration.subActive = false, openSubmenu.inspiration[item.id] = false"
                        >
                          <div class="w-60 h-60 shrink-0 bg-primary-100 group-hover/button:bg-primary-200 transition-colors rounded-md flex items-center justify-center">
                            <ChevronIcon class="w-25 rotate-90" />
                          </div>
                          <span class="block w-full ellipsis">
                            {{ item.title }}
                          </span>
                        </button>
                        <ul class="py-20">
                          <li
                            v-for="child in item.children"
                            :key="`inspiration-subchilditem-${child.id}`"
                            class="flex items-center justify-between gap-x-5"
                          >
                            <NuxtLink
                              :to="`/${child.uri}`"
                              class="px-20 py-[12px] lg:py-10 text-xl md:text-2xl lg:text-sm xl:text-lg leading-none text-left text-primary-500 flex items-center justify-between flex-1 lg:hover:underline"
                            >
                              {{ child.title }}
                            </NuxtLink>
                          </li>
                          <li class="lg:hidden p-20">
                            <NuxtLink
                              :to="`/${item.uri}`"
                              class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-base px-20 py-15 h-full items-center justify-center flex font-medium"
                            >
                              {{ $t('nav.explore-all', { title: item.title }) }}
                            </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      v-if="banner?.inspirationTitle"
                      class="p-10 lg:p-20 lg:col-span-4"
                    >
                      <div class="px-20 py-25 bg-primary-100">
                        <h4
                          class="wysiwyg-title text-2xl lg:text-xl xl:text-2xl"
                          v-html="banner.inspirationTitle"
                        />
                        <NuxtLink
                          v-if="banner.inspirationLink?.url"
                          :to="banner.inspirationLink.url"
                          :target="banner.inspirationLink.target"
                          class="inline-flex items-center text-xl font-serif underline space-x-5 group mt-15 text-primary-500 transition-colors hover:text-primary-700"
                        >
                          <span>{{ banner.inspirationLink.text }}</span>
                          <ChevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
                        </NuxtLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li
                v-if="interiorAdvice"
                class="lg:hidden"
              >
                <NuxtLink
                  :to="`/${interiorAdvice.uri}`"
                  class="block flex-1 px-20 text-xl md:text-2xl font-medium hover:text-primary-500 transition-colors ellipsis"
                >
                  {{ $t('nav.free-interior-advice') }}
                </NuxtLink>
              </li>

              <li class="lg:hidden p-20">
                <hr class="border-primary-500/20" />
              </li>

              <li
                v-if="stores"
                class="lg:hidden"
              >
                <NuxtLink
                  :to="`/${stores.uri}`"
                  class="hover:text-primary-500 transition-colors hover:underline px-20 font-bold block text-sm py-3"
                >
                  {{ stores.title }}
                </NuxtLink>
              </li>

              <li
                v-if="about"
                class="lg:hidden"
              >
                <NuxtLink
                  :to="`/${about.uri}`"
                  class="hover:text-primary-500 transition-colors hover:underline px-20 font-bold text-sm block py-3"
                >
                  {{ about.title }}
                </NuxtLink>
              </li>

              <li
                v-for="lang in $i18n.locales.filter((l) => $i18n.locale !== l.code)"
                :key="`language-${lang.code}`"
                class="lg:hidden"
              >
                <NuxtLink
                  class="hover:text-primary-500 transition-colors hover:underline flex items-center gap-x-5 px-20 font-bold text-sm py-3"
                  :to="switchLocalePath(lang.code)"
                  :aria-label="lang.name"
                  external
                >
                  <div class="rounded-full overflow-hidden">
                    <Component
                      :is="langIcons[lang.code]"
                      width="18"
                      height="18"
                    />
                  </div>
                  {{ lang.name }}
                </NuxtLink>
              </li>

              <li
                v-for="store in stores?.stores"
                :key="`nav-store-${store.id}`"
                class="mt-20 text-primary-500 text-sm px-20 lg:hidden"
              >
                <b>{{ store.storeTitle }}</b><br>
                {{ store.addressStreet }}<br>
                <NuxtLink
                  :to="`/${stores?.uri}#open`"
                  class="underline hover:text-primary-700"
                  external
                >
                  {{ $t('nav.opening-times') }}
                </NuxtLink>
              </li>

              <li
                v-if="socials?.whatsapp"
                class="lg:hidden p-20"
              >
                <NuxtLink
                  class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-xs px-15 py-[12px] items-center flex gap-x-3 leading-none justify-center"
                  :to="`https://wa.me/${socials.whatsapp}`"
                  target="_blank"
                  external
                >
                  <WhatsappIcon
                    width="30"
                    height="30"
                    class="-translate-y-1"
                  />
                  <span>{{ $t('nav.whatsapp') }}</span>
                </NuxtLink>
              </li>
            </ul>
          </nav>

          <ul class="flex">
            <!-- Interior advice button -->
            <li
              v-if="interiorAdvice"
              class="hidden lg:flex p-5"
            >
              <NuxtLink
                :to="`/${interiorAdvice.uri}`"
                class="transition-colors bg-primary-300 hover:bg-primary-500 text-white text-base xl:text-lg px-20 xl:px-25 h-full items-center flex font-medium"
              >
                {{ $t('nav.free-interior-advice') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="`/${cartOverview?.uri}`"
                class="w-75 lg:w-auto lg:px-20 flex items-center justify-center hover:bg-primary-100 border-x border-primary-700 transition-colors h-full"
              >
                <span class="relative">
                  <CartIcon class="-translate-y-2" />
                  <span class="absolute bottom-0 left-0 h-[23px] w-20 text-center text-2xs leading-7 focus:bg-primary-100 focus:outline-none">
                    {{ cart?.totalQty }}
                  </span>
                </span>
              </NuxtLink>
            </li>
            <li class="hidden lg:block group/search">
              <button
                type="button"
                class="w-75 flex items-center justify-center hover:bg-primary-100 transition-colors h-full"
              >
                <MagnifyingGlassIcon class="w-25 pointer-events-none" />
              </button>
              <div class="absolute p-25 bg-white -inset-x-1 top-full border border-primary-700 invisible opacity-0 pointer-events-none transition-all group-hover/search:visible group-hover/search:opacity-100 group-hover/search:pointer-events-auto">
                <h5 class="text-xl font-bold leading-none">
                  {{ $t('search.product') }}
                </h5>
                <SearchForm @submit="onSearch" />
              </div>
            </li>
          </ul>

          <button
            type="button"
            class="lg:hidden w-75 py-30 flex items-center flex-col justify-between focus:bg-primary-100 focus:outline-none transition-colors"
            @click="menuIsOpen = !menuIsOpen"
          >
            <span class="w-25 h-1 bg-primary-700 block in-[.menu-is-open]:rotate-45 in-[.menu-is-open]:translate-y-[6px] transition-transform" />
            <span class="w-25 h-1 bg-primary-700 block in-[.menu-is-open]:opacity-0 transition-opacity" />
            <span class="w-25 h-1 bg-primary-700 block in-[.menu-is-open]:-rotate-45 in-[.menu-is-open]:-translate-y-[6px] transition-transform" />
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import {
  computed,
  useAsyncGql,
  useI18n,
  useState,
  useRoute,
  useRouter,
  onMounted,
  onUnmounted,
  ref,
  $fetch,
} from '#imports';

// Assets
import Logo from '@/assets/img/logo.svg';
import CartIcon from '@/assets/img/icon-cart.svg';
import ChevronIcon from '@/assets/img/icon-chevron.svg';
import PlusIcon from '@/assets/img/icon-plus.svg';
import CloseIcon from '@/assets/img/icon-close.svg';
import MagnifyingGlassIcon from '@/assets/img/icon-magnifying-glass.svg';
import GermanLangIcon from '@/assets/img/lang-de.svg';
import DutchLangIcon from '@/assets/img/lang-nl.svg';
import WhatsappIcon from '@/assets/img/icon-whatsapp.svg';

// Props
defineProps({
  reviews: {
    type: Object,
    required: true,
  },
});

// Data
const route = useRoute();
const router = useRouter();
const { locale } = useI18n({ useScope: 'global' });
const { data: navigation } = await useAsyncGql('navigation', { site: locale.value }, { key: 'navigation' });
const langIcons = {
  de: GermanLangIcon,
  nl: DutchLangIcon,
};

// States
const cart = useState('cart', () => ({
  totalQty: 0,
  totalPriceAsCurrency: '',
  totalDiscountAsCurrency: '',
  lineItems: [],
}));
const menuIsOpen = useState('menuIsOpen', () => false);
const searchQuery = useState('searchQuery');
const searchQueryRef = ref('');
const openSubmenu = ref({
  collection: { active: false, subActive: false },
  rooms: { active: false, subActive: false },
  accessories: { active: false, subActive: false },
  inspiration: { active: false, subActive: false },
});
const scrolled = ref(false);
const announcement = useState('announcement');

// Computed
const stores = computed(() => navigation.value?.stores);
const about = computed(() => navigation.value?.about);
const cartOverview = computed(() => navigation.value?.cartOverview);
const search = computed(() => navigation.value?.search);
const collection = computed(() => navigation.value?.collection);
const roomsOverview = computed(() => navigation.value?.roomsOverview);
const rooms = computed(() => navigation.value?.rooms);
const accessoriesOverview = computed(() => navigation.value?.accessoriesOverview);
const accessories = computed(() => navigation.value?.accessories);
const inspirationOverview = computed(() => navigation.value?.inspirationOverview);
const inspiration = computed(() => navigation.value?.inspiration);
const interiorAdvice = computed(() => navigation.value?.interiorAdvice);
const socials = computed(() => navigation.value?.socials);
const banner = computed(() => navigation.value?.banner);

// Methods
/**
 * Scroll handler.
 */
const handleScroll = () => { scrolled.value = window.scrollY > 0; };

/**
 * Search.
 *
 * @param {String} query
 */
const onSearch = (query) => {
  if (searchQuery.value) { searchQuery.value.search = query; }
  if (route.meta.handle !== 'search') {
    // Push to search results page with query
    router.push({ path: `/${search.value.uri}`, query: { q: query } });
  }
};

/**
 * Check if device can hover.
 */
const canHover = () => {
  if (window.matchMedia('(hover: hover)').matches) return true;

  return false;
};

/**
 * Show / hide submenu on hover.
 *
 * @param {Object} item
 * @param {String} id
 * @param {Boolean} state
 */
const handleMouseEvent = (item, id, state) => {
  const i = item;
  if (canHover()) { i[id] = state; }
};

// Lifecycle hooks
onMounted(async () => {
  window.addEventListener('scroll', handleScroll, { passive: true });

  const commerce = await $fetch('/actions/commerce/cart/get-cart', { ignoreResponseError: true });
  if (commerce) {
    cart.value = commerce.cart;
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll, { passive: true });
});
</script>

<style lang="postcss" scoped>
@tailwind utilities;

@layer utilities {
  .ellipsis {
    @apply whitespace-nowrap text-ellipsis overflow-hidden;
  }
}
</style>
